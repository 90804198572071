import Browser from './platforms/base.js';
import Vizio from './platforms/vizio.js';
import Enseo from './platforms/enseo.js';
import Tivo from './platforms/tivo.js';
import Comcast from './platforms/comcast.js';
import Samsung from './platforms/samsung.js';

const isVizio = process.env.REACT_APP_PLATFORM === 'vizio';
const isEnseo = process.env.REACT_APP_PLATFORM === 'enseo';

let PlatformConstructor;

switch (process.env.REACT_APP_PLATFORM) {
  case 'vizio':
    PlatformConstructor = Vizio;
    break;
  case 'enseo':
    PlatformConstructor = Enseo;
    break;
  case 'tivo':
    PlatformConstructor = Tivo;
    break;
  case 'comcast':
    PlatformConstructor = Comcast;
    break;
  case 'samsung':
    PlatformConstructor = Samsung;
    break;
  default:
    PlatformConstructor = Browser;
}

var ReactvPlatforms = {
  platform: new PlatformConstructor(),
  isVizio,
  isEnseo
};

export default ReactvPlatforms;
