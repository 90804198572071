import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  play,
  pause,
  setDuration,
  setCurrentTime
} from '../../reducers/audioPlayer';
import {
  checkpoint,
  done,
  next,
  report,
  pauseStation,
  stationPlaying,
  setPodcastSecondsPlayed,
  setLiveRadioCurrentSong
} from '../../reducers/station';
import { getStationCurrentTrack } from '../../api/content';
import ReactvPlatforms from '../../lib/reactv-platforms/index';
import HLS from 'hls.js';

import './AudioPlayer.scss';

const isPositiveNumOr0 = num => {
  return !isNaN(num) && num >= 0;
};
const getGoodNum = (first, second) => {
  if (isPositiveNumOr0(first)) return first;
  if (isPositiveNumOr0(second)) return second;
  return 0;
};

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.intervalId = null;

    this.state = { metadataAreLoaded: false };

    this.onEnded = this.onEnded.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.setPlayerSource = this.setPlayerSource.bind(this);
    this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
  }

  componentDidMount() {
    if (HLS.isSupported()) {
      this.hls = new HLS();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      audioPlayer,
      station,
      report,
      checkpoint,
      stationPlaying,
      setLiveRadioCurrentSong,
      setPodcastSecondsPlayed
    } = this.props;
    const { metadataAreLoaded } = this.state;

    if (
      audioPlayer.currentStreamUrl !== prevProps.audioPlayer.currentStreamUrl ||
      (audioPlayer.force && !prevProps.audioPlayer.force)
    ) {
      this.setPlayerSource(audioPlayer.currentStreamUrl);
      this.currentStreamUrlToUpdateOnLoad = null;
    } else if (audioPlayer.DO_PLAY && !prevProps.audioPlayer.DO_PLAY) {
      if (station.contentType === 'LIVE') {
        this.setPlayerSource(audioPlayer.currentStreamUrl);
      }
      this.play();
    } else if (audioPlayer.DO_PAUSE && !prevProps.audioPlayer.DO_PAUSE) {
      if (station.contentType === 'LIVE') {
        this.playerRef.current.src = '';
      }
      this.pause();
    }

    if (!metadataAreLoaded) {
      if (audioPlayer.currentTrackTimeChanged) {
        this.currentTrackTimeToUpdateOnLoad = audioPlayer.currentTrackTime;
      }

      return;
    }

    if (this.metadataLoaded && station.playing === false) {
      stationPlaying();
    }

    if (
      audioPlayer.currentTrackTimeChanged ||
      this.currentTrackTimeToUpdateOnLoad
    ) {
      if (this.playerRef.current.readyState < 1) {
        this.shouldSetOnReadyState = getGoodNum(
          audioPlayer.currentTrackTime,
          this.currentTrackTimeToUpdateOnLoad
        );
      } else {
        this.playerRef.current.currentTime = getGoodNum(
          audioPlayer.currentTrackTime,
          this.currentTrackTimeToUpdateOnLoad
        );
        if (this.currentTrackTimeToUpdateOnLoad) {
          this.currentTrackTimeToUpdateOnLoad = null;
          this.play();
        }
      }
    }

    if (
      prevProps.audioPlayer.currentTrackTime === 0 &&
      audioPlayer.currentTrackTime === 1
    ) {
      if (station.contentType !== 'LIVE' && station.contentType !== 'RADIO') {
        report('START', 0);
      }
    }
    if (
      prevProps.audioPlayer.audioPlayedSeconds === 14 &&
      audioPlayer.audioPlayedSeconds === 15
    ) {
      if (station.contentType !== 'LIVE' && station.contentType !== 'RADIO') {
        checkpoint();
      }
    }

    if (
      station.contentType === 'PODCAST_EPISODE' &&
      audioPlayer.currentTrackTime &&
      audioPlayer.currentTrackTime - 30 >= station.secondsPlayed
    ) {
      setPodcastSecondsPlayed();
    }

    if (
      (this.intervalId &&
        station.contentType !== 'LIVE' &&
        station.contentType !== 'RADIO') ||
      (this.intervalId && this.lastIntervalStationId !== station.contentId)
    ) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (
      (station.contentType === 'LIVE' || station.contentType === 'RADIO') &&
      !this.intervalId
    ) {
      this.lastIntervalStationId = station.contentId;
      this.intervalId = window.setInterval(async () => {
        if (station.contentId) {
          const stationCurrentTrack = await getStationCurrentTrack(
            station.contentId
          );
          setLiveRadioCurrentSong(stationCurrentTrack);
        }
      }, 30000);
    }
  }

  play() {
    this.playerRef.current.play();
    this.props.pauseStation(false);
  }

  pause() {
    this.playerRef.current.pause();
    this.props.pauseStation(true);
  }

  onLoadedMetadata(event) {
    const { station } = this.props;
    this.metadataLoaded = true;
    if (station.contentType === 'LIVE' || station.contentType === 'RADIO') {
      return;
    }
    const duration = Math.round(event.target.duration);
    this.setState({ metadataAreLoaded: true });
    this.props.setDuration(duration);

    if (!this.currentTrackTimeToUpdateOnLoad) {
      this.play();
    }
  }

  onTimeUpdate(event) {
    const { station, audioPlayer } = this.props;
    if (station.contentType === 'LIVE' || station.contentType === 'RADIO') {
      return;
    }

    let time = Math.round(event.target.currentTime);

    if (time > 0) {
      if (time - audioPlayer.currentTrackTime >= 1) {
        this.props.setCurrentTime(time);
      }
    }
  }

  onEnded(event) {
    const { done } = this.props;
    done();
  }

  setPlayerSource(newSrc) {
    this.metadataLoaded = false;
    if (!newSrc) {
      return;
    }
    const { station, session } = this.props;
    let userAge =
      session.userProfile && session.userProfile.birthYear
        ? new Date().getFullYear() - session.userProfile.birthYear
        : 'null';
    let gender =
      (session.userProfile && session.userProfile.gender === 'male'
        ? 1
        : session.userProfile.gender === 'female'
        ? 2
        : 'null') || 'null';
    let lat = 'null',
      lon = 'null';
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        if (position && position.coords) {
          lat = position.coords.latitude;
          lon = position.coords.longitude;
        }
      });
    }

    let params = {
      iheartradioversion: process.env.REACT_APP_VERSION,
      listenerId: ReactvPlatforms.platform.getDeviceId(),
      amsparams: encodeURI(`playerid:iHeartRadioVizio;skey:${Date.now()}`), // TODO
      Deviceid: ReactvPlatforms.platform.getDeviceId(),
      clienttype: ReactvPlatforms.platform.getClientType(),
      osVersion: ReactvPlatforms.platform.firmware(),
      devicename: ReactvPlatforms.platform.getDeviceName(),
      callLetters: station.callLetters,
      streamid: station.contentId,
      terminalid: ReactvPlatforms.platform.getTerminalId(),
      init_id: '8169',
      profileid: session.profileId || '',
      fb_broadcast: 0,
      at: 0,
      awparams: `n:${userAge}:g${gender}`, // age, gender (1: male, 2: female)
      lat,
      lon
    };

    let paramsString = Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join('&');

    if (newSrc.includes('.m3u8') && HLS.isSupported()) {
      let video = document.getElementById('html5Player');

      let source = `${newSrc}?${paramsString}`;

      this.hls.loadSource(source);
      this.hls.attachMedia(video);
      this.hls.on(
        HLS.Events.MANIFEST_PARSED,
        function() {
          this.play();
        }.bind(this)
      );
    } else {
      if (this.playerRef.current && newSrc) {
        let source = `${newSrc}?${paramsString}`;
        this.playerRef.current.src = source;
        this.play();
      }
    }
  }

  render() {
    return (
      <div className="audioPlayer">
        <video
          ref={this.playerRef}
          id="html5Player"
          onLoadedMetadata={this.onLoadedMetadata}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onEnded}
          onDurationChange={e => {
            if (e.target.readyState > 0 && this.shouldSetOnReadyState) {
              this.playerRef.current.currentTime = this.shouldSetOnReadyState;
              this.currentTrackTimeToUpdateOnLoad = null;
              this.shouldSetOnReadyState = null;
              this.play();
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { audioPlayer, station, session } = state;
  return { audioPlayer, station, session };
};

export default connect(
  mapStateToProps,
  {
    checkpoint,
    done,
    pauseStation,
    play,
    pause,
    next,
    report,
    setPodcastSecondsPlayed,
    setDuration,
    setCurrentTime,
    setLiveRadioCurrentSong,
    stationPlaying
  }
)(AudioPlayer);
